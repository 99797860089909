import Joi from "joi";

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    name: Joi.string()
        .min(3)
        .max(255)
        .trim()
        .required(),
    nationality: Joi.string()
        .min(2)
        .max(50)
        .trim()
        .optional()
        .allow(null, ""),
    national_id: Joi.string()
        .min(5)
        .max(30)
        .trim()
        .required(),
    sex: Joi.string()
        .valid('male', 'female')
        .required(),
    weight: Joi.number()
        .integer()
        .min(1)
        .max(500)
        .optional()
        .allow(null,""),
    growth: Joi.number()
        .integer()
        .min(10)
        .max(300)
        .optional()
        .allow(null,""),
    home_address: Joi.string()
        .min(2)
        .max(100)
        .optional()
        .allow(null,""),
    post_address: Joi.string()
        .min(2)
        .max(100)
        .optional()
        .allow(null,""),
    phone: Joi.string().min(9)
        .max(14)
        .pattern(/^[+0-9]+$/)
        .optional()
        .allow(null, ""),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .trim()
        .optional()
        .allow(null, ""),
    birth_date: Joi.date()
        .required(),
    notes: Joi.string()
        .max(1000)
        .optional()
        .allow(null, ""),
    summary: Joi.string()
        .max(3000)
        .optional()
        .allow(null, ""),
    enabled: Joi.boolean()
        .optional(),
    videos: Joi.array()
        .items(
            Joi.object({
                _id: Joi.string()
                    .regex(/^[0-9a-fA-F]{24}$/)
                    .optional(),
                video: Joi.string()
                    .regex(/^[0-9a-fA-F]{24}$/)
                    .required(),
                enabled: Joi.boolean()
                    .optional(),
                views: Joi.number()
                    .integer()
                    .min(0)
                    .optional()
            })
        )
        .optional()
});

export const create = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    name: Joi.string()
        .min(3)
        .max(255)
        .trim()
        .required(),
    nationality: Joi.string()
        .min(2)
        .max(50)
        .trim()
        .optional()
        .allow(null, ""),
    national_id: Joi.string()
        .min(5)
        .max(30)
        .trim()
        .required(),
    sex: Joi.string()
        .valid('male', 'female')
        .required(),
    weight: Joi.number()
        .integer()
        .min(1)
        .max(500)
        .optional()
        .allow(null, ""),
    growth: Joi.number()
        .integer()
        .min(10)
        .max(300)
        .optional()
        .allow(null, ""),
    home_address: Joi.string()
        .min(2)
        .max(100)
        .optional()
        .allow(null, ""),
    post_address: Joi.string()
        .min(2)
        .max(100)
        .optional()
        .allow(null, ""),
    phone: Joi.string()
        .min(9)
        .max(14)
        .pattern(/^[+0-9]+$/)
        .optional()
        .allow(null, ""),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .trim()
        .optional()
        .allow(null, ""),
    birth_date: Joi.date()
        .required()
});
