<template>
    <Dialog maxWidth="700px" :title="$t('patient.create.title')" :saveProgress="saveProgress" :open="open" @cancel="$emit('cancel')" @save="save()">
        <v-row>
            <v-col class="col-12 col-md-8 pt-3 pr-md-1">    
                <v-text-field
                    :label="$t('patient.form.name')"
                    v-model="form.name"
                    :error-messages="errors.name"
                    :hide-details="!errors.name"
                    :disabled="saveProgress"
                    @change="validate('name')"
                    outlined
                    prepend-inner-icon="mdi-account"
                />
            </v-col>

            <v-col class="col-12 col-md-4 pt-0 pl-md-1 pt-md-3">
                <v-select
                    class="mt-0"
                    v-model="form.sex"
                    :label="$t('patient.form.sex')"
                    :items="sex_items"
                    :hide-details="!errors.sex"
                    :error-messages="errors.sex"
                    :disabled="saveProgress"
                    @change="validate('sex')"
                    item-text="name"
                    item-value="key"
                    outlined
                    prepend-inner-icon="mdi-gender-male-female"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col class="col-12 col-md-8 pt-0 pr-md-1">    
                <v-text-field
                    :label="$t('patient.form.national_id')"
                    v-model="form.national_id"
                    :error-messages="errors.national_id"
                    :hide-details="!errors.national_id"
                    :disabled="saveProgress"
                    @change="validate('national_id')"
                    outlined
                    prepend-inner-icon="mdi-card-account-details-outline"
                />
            </v-col>

            <v-col class="col-12 col-md-4 pt-0 pl-md-1">
                <v-text-field
                    class="mt-0"
                    :label="$t('patient.form.nationality')"
                    v-model="form.nationality"
                    :error-messages="errors.nationality"
                    :hide-details="!errors.nationality"
                    :disabled="saveProgress"
                    @change="validate('nationality')"
                    outlined
                    prepend-inner-icon="mdi-earth"
                />
            </v-col>
        </v-row>

        <div class="text-left mt-2 font-weight-light text-caption mb-2">{{$t('patient.form.birth_date')}}</div>
        <DateInput
            class="mb-7"
            v-model="pickedDate"
            :errorMessage="errors.birth_date"
            :disabled="saveProgress"
            @change="validate('birth_date')"
            :yearLabel="$t('year')"
            :monthLabel="$t('month')"
            :dayLabel="$t('day')"
        />
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import DateInput from '../components/DateInput.vue';

import validator from "../../plugins/validator";
import * as patientValidation from "../../api/patient/validation";

export default {
    components: {
        Dialog,
        DateInput
    },

    async mounted() {
        this.sex_items.push({
            key: "female",
            name: this.$t("sex_types.female.name")
        });

        this.sex_items.push({
            key: "male",
            name: this.$t("sex_types.male.name")
        });
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        entity: {
            type: String,
            default: null
        }
    },

    data: () => ({
        pickedDate: {
            year: null,
            month: null,
            day: null,
        },

        form: {
            name: null,
            nationality: null,
            national_id: null,
            birth_date: null,
            sex: null
        },

        errors: {
            name: null,
            nationality: null,
            national_id: null,
            birth_date: null,
            sex: null
        },

        show: true,
        saveProgress: false,
        sex_items: []
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            this.form.birth_date = this.pickedDate.year + "-" + String(this.pickedDate.month).padStart(2, '0') + "-" + String(this.pickedDate.day).padStart(2, '0');

            return validator(
                this.form,
                patientValidation.create,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if(!this.validate()) return;
            if(this.entity) this.form.entity = this.entity;

            this.saveProgress = true;
            let res = await this.$store.dispatch("api/patient/create", this.form);
            this.saveProgress = false;

            if (res.status == 200) {
                this.$emit("save");
                this.$emit("cancel");
            }
        }
    }
};
</script>
