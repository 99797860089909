<template>
    <Page icon="mdi-medical-bag" :title="$t('routes.patients')" :progress="progress">
        <ActionBar @filter="s => load(s)" :searchProgress="searchProgress">
        <v-row class="d-flex ma-0 pa-0">
            <v-col class="d-inline-flex align-center justify-end ma-0 pa-0">
                <v-checkbox 
                    v-if="!form.anonymised && form.enabled"
                    class="ma-0 pa-0"
                    v-model="form.not_ranked"
                    hide-details
                    :label="$t('patient.waiting_for_rating')"
                    @change="load(form.filter)"
                ></v-checkbox>
                <v-btn @click="dialog = true" :open="dialog" @cancel="dialog = false" class="primary ml-7">
                    <v-icon left>mdi-plus</v-icon> {{ $t("patient.create.title") }}
                </v-btn>
            </v-col>
        </v-row>
        </ActionBar>

        <v-tabs class="mt-3">
            <v-tab @click="tab = 0; tabChange()">{{$t('patient.list_tabs.enabled')}}</v-tab>
            <v-tab @click="tab = 1; tabChange()">{{$t('patient.list_tabs.disabled')}}</v-tab>
            <v-tab @click="tab = 2; tabChange()">{{$t('patient.list_tabs.anonymised')}}</v-tab>
        </v-tabs>

        <v-simple-table>
            <thead>
                <tr>
                    <th class="primary--text">
                        <SortHeader 
                            :text="form.anonymised ? $t('patient.list.anonymised_name') : $t('patient.list.name')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text" v-if="!form.anonymised">
                        <SortHeader 
                            :text="$t('patient.list.national_id')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="national_id"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text" v-if="!form.anonymised">
                        <SortHeader 
                            :text="$t('patient.list.phone')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="phone"
                            type="numeric"
                        />
                    </th>
                    <th class="primary--text text-center" v-if="!form.anonymised">
                        <SortHeader 
                            :text="$t('patient.list.num_devices')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="num_devices"
                            type="numeric"
                        />
                    </th>
                    <th class="primary--text text-center" v-if="!form.anonymised">
                        <SortHeader 
                            :text="$t('patient.list.checked')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="checked"
                            type="boolean"
                        />
                    </th>
                    <th class="primary--text w-action">
                        {{ $t("patient.list.edit") }}
                    </th>
                </tr>
            </thead>
            <tbody style="white-space: nowrap;">
                <tr v-for="(u, index) in patients" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <v-icon size="16" v-if="form.anonymised">mdi-music-accidental-sharp</v-icon><span class="font-weight-bold">{{ u.name }}</span>
                            <v-avatar
                                v-if="u.unrankedTests > 0"
                                color="light-blue darken-2"
                                size="15"
                                class="ml-2 mb-1"
                            >
                                <span v-if="u.unrankedTests < 10" class="white--text" style="font-size: 10px;">{{u.unrankedTests}}</span>
                                <span v-else class="white--text" style="font-size: 10px;">9+</span>
                            </v-avatar>
                        </tr>
                        <tr v-if="!form.anonymised">
                            <span class="grey--text text--darken-2">
                                <v-icon size="16">mdi-calendar-range</v-icon> {{ (new Date(u.birth_date)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}) }}
                            </span>
                        </tr>
                    </td>
                    
                    <td v-if="!form.anonymised">
                        <v-icon class="mr-1" size="16">mdi-card-account-details-outline</v-icon> {{ (u.national_id) ? u.national_id : $t("empty") }}
                    </td>

                    <td v-if="!form.anonymised">
                        <v-icon class="mr-1" size="16">mdi-phone</v-icon> {{ (u.phone) ? u.phone : $t("empty") }}
                    </td>

                    <td class="text-center" v-if="!form.anonymised">
                        <v-icon class="mr-2" size="16">mdi-tablet-cellphone</v-icon> {{ u.num_devices }}
                    </td>

                    <td class="text-center" v-if="!form.anonymised">
                        <v-icon v-if="u.checked" color="primary">mdi-check-bold</v-icon>
                        <v-icon v-if="!u.checked" color="red darken-1">mdi-close-thick</v-icon>
                    </td>

                    <td class="w-action">
                        <v-btn
                            fab
                            :color="!u.checked ? 'red darken-1' : 'primary'"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'patient', params: { id: u._id, routefrom: 'patients' } }"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
        />

        <PatientCreate 
            :entity="selectedEntity()" 
            v-if="dialog" 
            :open="dialog" 
            @cancel="dialog = false" 
            @save="load(form.filter)" 
        />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import SortHeader from "./components/SortHeader.vue";
import PatientCreate from "../views/dialogs/PatientCreate.vue";

export default {
    components: {
        Page,
        ActionBar,
        SortHeader,
        PatientCreate
    },

    async mounted() {
        this.form.not_ranked = this.$route.params.not_ranked ? this.$route.params.not_ranked : false;
        this.load();
    },

    data: () => ({
        dialog: false,
        progress: true,
        searchProgress: false,
        patients: null,
        tab: null,

        pagination: {
            total: 0,
            filter: null
        },
        
        form: {
            sort: "checked name ",
            page: 1,
            limit: null,
            filter: null,
            not_ranked: false,
            enabled: true,
            anonymised: false,

        }
    }),
    
    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load(filter) {
            if (filter) this.form.filter = filter;
            else this.form.filter = null;

            if(this.pagination.filter != filter) this.form.page = 1;
            this.pagination.filter = filter;
            
            this.searchProgress = !this.progress;

            this.selectedEntity();

            const res = await this.$store.dispatch("api/patient/all", this.form);

            if (res.task.count == 0) {
                this.progress = false;
                this.searchProgress = false;

                if(res.status == 200) {
                    this.patients = res.data;
                    this.pagination.total = res.pages;
                }
            }
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        },

        tabChange(){
            switch (this.tab) {
                case 0:
                    this.form.enabled = true;                
                    this.form.anonymised = false;   
                    break;
                case 1:
                    this.form.enabled = false;                
                    this.form.anonymised = false;
                    break;
                case 2:
                    this.form.enabled = false;                
                    this.form.anonymised = true;   
                    break;
            }
            this.load(this.form.filter)
        }
    }
};
</script>
